body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
p {
    margin-bottom: 0;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.row > * {
    padding: 0;
    padding-left: 0;
    padding-right: 0;
}
.row {
    --bs-gutter-x: 0;
}
:root {
    --orangeColor: #f47e20;
    --blueColor: rgba(28, 27, 77, 1);
}

.hr-divider {
    height: 2px;
    border: 1.5px solid black;
}
/* .css-1f8sh1y{
height:200px !important
} */

/* for mobile */

.whatsapp_float {
    display: none !important;
}

@media screen and (max-width: 768px) {
    .whatsapp_float {
        display: flex !important;
        align-items: center;
        position: fixed;
        color: #fff;
        width: 100%;
        height: 60px;
        bottom: 0px;
        right: 0px;
        z-index: 99999;
    }
    .whatsapp_float .callNow {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: black;
        width: 100%;
        height: 100%;
        color: #fff;
        text-decoration: none !important;
        font-size: 18px;
    }

    .whatsapp_float .whatsApp {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #25d366;
        width: 100%;
        height: 100%;
        color: #fff;
        font-size: 18px;
    }
    .whatsapp_float span {
        margin-left: 6px;
    }
}
