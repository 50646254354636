.helpSection {
    width: 90%;
    margin: auto;
    text-align: left;
    padding-bottom: 70px;
    margin-top: 100px;
}

.promiseSection {
    width: 90%;
    margin: auto;
}

.helpDiv {
    background: url("../Images/HelpBg.png");
    background-repeat: no-repeat;
    background-size: cover;
}
.helpSection h4 {
    font-size: 30px;
    color: #303030;
    font-weight: 600;
}

.helpPara {
    width: 75%;
    font-weight: 500;
    line-height: 28px;
    margin: 20px 0;
}
.WhyUsli {
    font-weight: 500;
    line-height: 28px;
    margin: 20px 80px;
}
.portfolioItem {
    cursor: pointer;
}
.portfolioItem:hover {
    font-weight: bold;
    background-color: rgba(244, 126, 32, 1);
    color: white;
}
.aspirePromise-img {
    width: 30%;
    padding: 58px;
    color: white;
    border-radius: 20%;
    background-color: var(--blueColor);
    position: absolute;
    right: -5%;
    bottom: 0%;
}

@media (max-width: 568px) {
    .aspirePromise-img {
        width: 100%;
        padding: 50px;
        position: static;
        border-radius: 0;
        /* top: 0; */
    }
}
