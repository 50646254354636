.getInTouch{
    width: 80%;
    margin: auto;
    margin-top: 100px;
}

.getInTouchSub{
    font-weight: 600;
}

.getInTouchForm{
    border-radius: 20px;
    border: 1px solid #0000000F;
    box-shadow: 10px 10px 4px 0px #00000040;
    padding: 20px;
    
}

.contactFormRow{
    margin-top: 100px !important;
}

.inputForm{

    border: none;
    border-bottom: 1px solid #52525242;
    width: 90% ;
}

.inputForm::placeholder{
    color: black;
    font-weight: 500;
}
.inputForm:focus{
    outline: none;
}

.messageInput{
    margin-top: 120px;
    width: 95%;
}

.contactButton{
    background-color: #13184F;
    color: white;
    font-weight: 500;
    font-size: 16px;
    margin-top: 70px;
    width: 60%;
    padding: 10px;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
}

.sideIcons{
    background-color: #F47E20;
    color: white;
    border-top-right-radius: 150px;
    border-bottom-right-radius: 150px;
}

.sideIconRow{
    height: 33%;
    display: flex;
    align-items: center;
}
@media (max-width: 992px){
    .GetInTouch {
        padding-left: 20px;
        padding-bottom: 40px;
    }
    
}