
.portfolioItem{
    background: rgba(28, 27, 77, 0.1);
    border-radius: 10px;
    margin: 10px 0;
    padding: 10px 0;
    width: 98%;
    
}
.portfolioItem p{
    padding: 0 7px;
}
.countryContainer{
    overflow-y: scroll;
    overflow-x: hidden;
    height: 55vh;
}
.activeportfolio{
    background-color: rgba(244, 126, 32, 1);
    color: white;
}
.countryContainer::-webkit-scrollbar {
    width: 7px;
  }
  
.countryContainer::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
.countryContainer::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }  
  
.portfolioContainer{
    position: relative;
}
.propertyDescription{
    position: absolute;
    bottom: 0;
    right: 6.5%;
    width: 38%;
    z-index: 1000;
}
.description{
    background-color: rgba(243, 243, 243, 1);
    border-radius: 15px 15px 0 0;
    padding: 15px 35px;
}
.description h3{
    font-size: 1rem;
    font-weight: 700;
}
.description h5{
    font-size: 0.8rem;
}
.propertyFeatures{
    display: flex;
    flex-wrap: wrap;
    padding: 2px 0;
}
.propertyFeatures span{
    display: flex;
    width: 50%;
    margin: 2px 0;
    align-items: center;
}
.propertyFeatures span h4 {
    font-size: 0.8rem;
    margin: 0 5px;
}
.propertyFeatures span p {
    font-size: 0.8rem;
    margin: 0 5px;
    font-weight: 500;
}
.propertyDescription button{
    width: 100%;
    background-color: rgba(244, 126, 32, 1);
    border-radius: 10px;
    padding: 8px;
    color: white;
    font-size: 1rem;
    border: none;
}
.propertyDescription a:hover{
    background-color: white;
    color: var(--orangeColor);
    transition: 1s;
}
.newhover:hover{
    transition: ease;
    transition: 0.5s;
}
.item-enter {
    opacity: 0;
  }
  .item-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  .item-exit {
    opacity: 1;
  }
  .item-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
  }
  .property-img{
    animation-name: img;
    animation-duration: 1s;
    animation-iteration-count: once;
    z-index: -1;
    transition: ease;
    opacity: 1;
  }
  @keyframes img {
    0%{
        opacity: 0
    }100%{
        opacity: 1;
    }
  }

@media (max-width: 768px){
    .propertyDescription{
        top: 20%;
        width: 90%;
        bottom: unset;
        left:5%;
    }
    .property-img-height{
        height:40vh;
    }
    .alternate-text-property{
        height: 40vh;
    }

    .location-container{
        margin-top: 20%;
    }
    .property-head{
        margin-bottom: 20px !important;
    }

}

@media (max-width: 520px){
    .location-container{
        margin-top: 5%;     
    }

    .portfolioItem p {
        padding: 0 15px;
        font-size: 14px;

    }
    .propertyDescription{
        top: 23%;
        width: 90%;
        bottom: unset;
    }
    .description h3{
        font-size: 14px;
        margin-bottom: 3px;
    }
    .description h5{
        font-size: 12px;
        margin-bottom: 6px;
    }
    .propertyFeatures span svg{
        font-size: 15px;

    }
    .propertyFeatures span h4{
        font-size: 10px;
    }
    .description {
        padding: 10px 20px;
    }
    .propertyDescription button {
        width: 100%;
        background-color: rgba(244, 126, 32, 1);
        border-radius: 10px;
        padding: 8px;
        color: white;
        font-size: 12px;
    }    
    .property-head {
        font-size: 25px !important;
    }
    .property-sidehead h5 {
        font-size: 16px;
    }
    .project-property-height{
        height: 40px;
        margin-bottom: 0;
    }
}
@media (max-width: 420px){
    .location-container{
        margin-top: 20%;     
    }
}