.loader{
    background-color: var(--blueColor);
    padding: 35.9vh 0;
    position: relative;
    height: 100vh;
}
.cap{
    animation: cap;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    transition: 0.2s;
}
@keyframes cap {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}