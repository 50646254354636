.browseTop{
    height: 100vh;
    background: url("../../Assets/Images/ProjectTopImage.png");
    /* background-repeat: no-repeat; */
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.searchDiv{
    width: 500px;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
}

.indCardDiv{
    border: 1.05px solid #52525217;
    border-radius: 10px;
    padding: 10px 0

}
.property{
    width: 90%;
    margin: auto;
    text-align: left;
    padding-bottom: 70px;
    margin-top: 70px;
    z-index: 1000;
    
}
.property-img{
    animation-name: img;
    animation-duration: 0.5s;
    animation-iteration-count: once;
    z-index: -1;
    transition: ease;
    opacity: 1;
  }
  
  @keyframes img {
    0%{
        opacity: 0
    }100%{
        opacity: 1;
    }
  }
.property-head{
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 70px;
}
.property-sidehead{
    display: flex;
    padding-top: 10px;
    justify-content: space-evenly;
}
.property-sidehead h5{
    font-weight: 600;
    cursor: pointer;
}
.map-head-active{
    color: var(--orangeColor);
}



/* map view */
.map-viewimg img{
    width: 90%;
}
.propertyDescription-map-view button{
    width: 100%;
    background-color: rgba(244, 126, 32, 1);
    border-radius: 10px;
    padding: 8px;
    color: white;
    font-size: 1rem;
    border: none;
}
.propertyDescription-map-view{
    position: relative;
    width: 76%;
    left: 7%;
    bottom: 100px;
}
.map-view-scroll{
    height: 60vh;
    overflow: hidden;
    overflow-y: scroll;
}
.propertyDescription-map-view {
left: 7%;
}
.map-view-scroll::-webkit-scrollbar {
    width: 7px;
  }
  
.map-view-scroll::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
.map-view-scroll::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }  
  
@media (max-width: 992px){
    .map-viewimg img{
        width: 100%;
    }
    .propertyDescription-map-view {
        position: relative;
        width: 85%;
        left: 3%;
        bottom: 100px;
    }
    .property-img-height{
        width: 100% !important;
    }
}
@media (max-width: 768px){
.map-view-scroll{
    height: 60vh;
    overflow: hidden;
}
}