.perfectWriteDiv{
    width: 80%;
    margin: auto;
    text-align: center;
    padding-left: 20px;
    z-index: 5;
}

.perfectSection{
    padding: 30px 0;
}
.homeBuyTop{
    display: flex;
    align-items: center;
    color: white;
    padding: 20% 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),url("../Images/homebg.jpg") ;
    background-repeat: no-repeat;
    background-size: cover;
}

.homeBuyingHead{
    font-weight: 600;
    font-size: 42px;
}

.homechoiceSection{
    /* margin-top: 30px; */
    background: linear-gradient(to right top, rgba(0, 0, 0, 0.7), rgba(13, 13, 13, 0.6), rgba(11, 11, 11,0.5),rgba(11, 11, 11,0.5) ,rgba(11, 11, 11,0.5)), url("https://img.freepik.com/premium-photo/real-estate-business-building-technology_123766-48.jpg");
    display: flex;
    align-items: center;
    color: #FFFFFF;
    position: relative;
    background-position: center;
    object-fit: cover;
    background-repeat: no-repeat;
    padding: 100px 0;
    background-size: cover;
    
}

.homeChoiceImageDiv{
    position: absolute;
    top: 0px;
    z-index: 1;

}
.homeChoiceImageDiv img{
    object-fit: cover;
    background-size: cover;
    
}

.homeChoiceHead{
    font-weight: 600;
    font-size: 42px;
}

.perfectBg{
    background:linear-gradient(to right top, rgba(0, 0, 0, 0.7), rgba(13, 13, 13, 0.6), rgba(11, 11, 11,0.5),rgba(11, 11, 11,0.5) ,rgba(11, 11, 11,0.5)), url("../../Assets/Images/perfectHome.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 15% 0;
}

.changeGameDiv{
    background: linear-gradient(to bottom right, rgba(255, 117, 39, 0.75) 0%, rgba(255, 117, 39, 0.75) 50%,  rgba(0, 0, 0, 0.2) 50%,  rgba(0, 0, 0, 0.2) 100%), url("../Images/becomePartner.jpg");;
    background-position: center;
    object-fit: cover;
    height: 80vh;
    border-radius: 32px;
    width: 90%;
    margin: auto;
    margin-bottom: 90px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
}

.changeGameSection{
    margin-top: 100px;
}


.CommunityCarousel{
    margin-top: 80px;
    
}
.CarouselDate{
    font-size: 1rem;
    color: gray;
}
.homeChoiceImageDiv img{
    width: 200px;
}

.Link-special-banner{
    width: 100%;
}
.link-width{
    width: 35%;
}
@media (max-width: 992px){
    .homeChoiceImageDiv img{
        width: 50%;
    }
}
@media (max-width: 568px){
    .homeChoiceImageDiv img{
        width: 40%;
    }
    .perfectWriteDiv{
        padding-left: 0;
    }
    .perfectWriteDiv h2{
        font-size: 36px;
    }
    .perfectWriteDiv p{
        font-size: 14px;
    }
    .Link-special-banner{
        width: 65%;
    }
    .link-width{
        width: 80%!important;
    }
}