.ad-sec2-left {
  text-align: center;
}
.ad-sec2-left h3 {
  font-size: 30px;
  color: #303030;
  font-weight: 600;
}

.head-underline {
  height: 5px;
  border-radius: 20px;
  width: 135px;
  background: linear-gradient(90deg, #13184f, #f47e20 94.96%);
  margin: 0 auto;
}

.left-card {
  background-color: #13184f;
  width: 25%;
  margin: 0 auto;
  padding: 20px 30px;
  border-radius: 10px;
}
.left-card h5 {
  line-height: 30px;
  color: white;
}

.ad-card-img i {
  font-size: 40px;
  color: rgba(244, 126, 32, 1);
}

.register-box {
  box-shadow: 0px 0px 5px gray;
  border-radius: 10px;
}

.register-box h5 {
  height: 50px;
  text-align: center;
  padding: 15px;
  background-color: #13184f;
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.register-field {
  display: flex;
  flex-direction: column;
  background-color: #f3e6c4;
  padding: 0 10px;
}

.register-field input,
select {
  margin: 10px 0;
  height: 35px;
  border: 2px solid rgba(244, 126, 32, 1);
  border-radius: 10px;
  background: transparent;
  width: 100%;
  padding: 0 10px;
}

.register-field input::placeholder {
  padding-left: 10px;
  color: gray;
}
.register-field select {
  padding-left: 8px;
  color: gray;
}

.register-field p {
  font-size: 15px;
}

.register-field a {
  color: rgba(244, 126, 32, 1);
}

.register-btn {
  text-align: center;
  margin: 20px 0;
}

.register-btn button {
  width: 120px;
  height: 40px;
  border: none;
  background-color: rgba(244, 126, 32, 1);
  color: white;
  border-radius: 10px;
}

.register-btn button:hover {
  background-color: white;
  color: #f47e20;
  transition: 0.3s;
}

/* -------------- Sec 3- -------------------- */
.sec3-container {
  padding: 50px 70px;
  background-color: #f1efef;
}
.sec3-container h3 {
  text-align: left;
  font-size: 30px;
  color: #303030;
  font-weight: 600;
}

.sec-list ul li {
  line-height: 30px;
}

.project-list {
  flex-wrap: wrap;
}

.flat-card {
  width: 40%;
}

.flat-img {
  width: 100%;
  height: 60%;
}

/* -------------------- Sec 4 ----------------- */
.sec4-container h3 {
  text-align: center;
}
.project-heading {
  font-size: 30px;
  color: #303030;
  font-weight: 600;
}

.project-underline {
  height: 5px;
  border-radius: 20px;
  width: 115px;
  background: linear-gradient(90deg, #13184f, #f47e20 94.96%);
  margin: 0 auto;
}

.align-item-card {
  left: 0 !important;
  margin: 0 auto;
}

/* --------------- Sec 5 ------------------------ */
.sec5 h3 {
  padding-left: 20px;
  text-align: left;
  font-size: 30px;
  color: #303030;
  font-weight: 600;
}

.tiles-card {
  width: 90%;
  margin: 0 auto;
  background-color: #13184f;
  padding: 40px;
  -webkit-transition: all 0.3s ease;
  height: 100%;
}

.tiles-card:hover {
  background-color: rgba(244, 126, 32, 1);
  /* transform: translateY(-5px); */
  color: #13184f;
  -webkit-transform: translateY(-5px);
}

.tiles-card i {
  font-size: 40px;
  margin-right: 15px;
  color: white;
}
.tiles-card h5 {
  font-size: 17px;
  color: white;
}
.tiles-card p {
  margin-top: 20px;
  color: white;
  font-size: 14px;
}

.tiles-card strong {
  color: #f47e20;
}

.tiles-card:hover strong {
  color: #13184f;
}

/* ----------------- Sec 6 ------------------- */
.project-whole-card {
  padding: 0 30px;
}

.site-details {
  padding: 10px 30px;
  background-color: #13184f;
  color: white;
  /* height: 100%; */
  height: 16.5rem;
  border-radius: 10px;
  margin: 0 10px;
}

.site-details h3 {
  font-size: 25px;
}

.site-img {
  /* height: 100%; */
  height: 16.5rem;
}

.sub-detail-container {
  margin-bottom: 1rem;
  align-items: center;
}

.sub-detail-container h4 {
  font-size: 15px;
  margin: 0;
}

.site-sub-details {
  margin-top: 30px !important;
}

.detail-icon {
  width: 20px !important;
  height: 20px !important;
  margin-right: 15px;
}

.view-property-btn {
  width: 100%;
  height: 30px;
  background-color: #f47e20;
  border-radius: 10px;
  border: none;
  color: white;
}

.view-property-btn:hover {
  background-color: white;
  color: #f47e20;
  transition: 0.3s;
}

.site-offer {
  /* height: 100%; */
  height: 16.5rem;
  background-color: #f47e20;
  color: white;
  padding: 20px 20px;
  border-radius: 10px;
}

.close-cancel-btn {
  position: relative;
  left: 7rem;
  font-size: 22px;
  cursor: pointer;
}

.boq-service {
  position: fixed;
  top: 15%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(121 121 121 / 50%);
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  z-index: 10000000000;
  opacity: 1;
  visibility: visible;
  transition: 0.3s;
  backdrop-filter: blur(5px);
}

.boq-service-content {
  width: 450px;
  position: relative;
  background-color: white;
  padding: 27px;
  color: black;
  border-radius: 5px;
}

.card-achv {
  display: flex;
  width: 40%;
  /* flex-direction: column; */
  align-items: center;
  height: auto;
  padding: 20px;
  background-color: #13184f;
}

.priority-container {
  width: 16rem;
  position: relative;
}
.download-button {
  margin: 0 auto;
}

.download-button button {
  width: 130px;
  height: 40px;
  background-color: #f47e20;
  color: white;
  border: none;
  border-radius: 10px;
}

.download-button button:hover {
  background-color: white;
  color: #f47e20;
  transition: 0.3s;
}

.close-pass-btn {
  color: white;
  font-size: 25px;
  position: relative;
  bottom: 14rem;
  left: 10px;
  cursor: pointer;
}

.user-id {
  position: absolute;
  color: white;
  bottom: 1rem;
  left: 2rem;
}

.ads-property-img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.ads-banner-web {
  display: block;
}
.ads-banner-mob {
  display: none;
}

.bhk-show p {
  /* font-size: 0.8rem; */
  margin: 0 5px;
  font-weight: bold;
}

.comming-soon {
  margin: auto 0;
  /* height: 70%; */
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 10px;
}
.comming-soon ul {
  padding-left: 15px;
}
.comming-soon ul li {
  line-height: 25px;
  /* font-size: 16px; */
  font-weight: 700;
}
.comming-soon h4 {
  font-size: 41px;
  font-weight: 600;
}
.offer-box span {
  color: #13184f;
  font-size: 32px;
  background: rgba(178, 178, 178, 0.542);
  border-radius: 5px;
  padding: 3px 6px;
}

@media only screen and (max-width: 1920px) {
  .site-img {
    height: 25rem;
  }
  .site-details {
    height: 25rem;
  }
  .site-offer {
    height: 25rem;
  }
  .detail-icon {
    width: 30px !important;
    height: 30px !important;
    margin-right: 15px;
  }
  .site-sub-details {
    margin-top: 60px !important;
  }
  .sub-detail-container h4 {
    font-size: 18px;
    margin: 0;
  }
  .view-property-btn {
    width: 100%;
    height: 40px;
  }
  .site-details {
    padding: 20px 30px;
    font-size: 18px;
  }
  .comming-soon ul li {
    line-height: 40px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 1710px) {
  .site-img {
    height: 21rem;
  }
  .site-details {
    height: 21rem;
  }
  .site-offer {
    height: 21rem;
  }
  .detail-icon {
    width: 30px !important;
    height: 30px !important;
    margin-right: 15px;
  }
  .site-sub-details {
    margin-top: 30px !important;
  }
  .sub-detail-container h4 {
    font-size: 18px;
    margin: 0;
  }
  .view-property-btn {
    width: 100%;
    height: 40px;
  }
  .site-details {
    padding: 20px 30px;
    font-size: 18px;
  }
  .comming-soon ul li {
    line-height: 35px;
    font-size: 17px;
  }
}

@media only screen and (max-width: 1425px) {
  .site-img {
    height: 18rem;
  }
  .site-details {
    height: 18rem;
  }
  .site-offer {
    height: 18rem;
  }
  .detail-icon {
    width: 20px !important;
    height: 20px !important;
    margin-right: 15px;
  }
  .site-sub-details {
    margin-top: 20px !important;
  }
  .sub-detail-container h4 {
    font-size: 15px;
    margin: 0;
  }
  .view-property-btn {
    width: 100%;
    height: 40px;
  }
  .site-details {
    padding: 20px 30px;
    font-size: 18px;
  }
  .comming-soon ul li {
    line-height: 30px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 1280px) {
  .site-img {
    height: 16.5rem;
  }
  .site-details {
    height: 16.5rem;
  }
  .site-offer {
    height: 16.5rem;
  }
  .site-details {
    padding: 20px 30px;
    font-size: 15px;
  }
  .view-property-btn {
    width: 100%;
    height: 30px;
  }
  .sub-detail-container h4 {
    font-size: 14px;
  }
  .comming-soon ul {
    padding-left: 15px;
  }
  .comming-soon ul li {
    line-height: 25px;
    font-size: 15px;
  }
}

@media only screen and (max-width: 820px) {
  .ad-sec2-left {
    margin-bottom: 20px;
  }
  .tiles-margin {
    margin: 10px 0;
  }
  .site-details {
    height: auto;
    margin: 0;
  }
  .ads-property-img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .site-offer {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .site-details {
    border-radius: 0px;
  }
  .comming-soon {
    justify-content: start;
  }
  .site-offer {
    height: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .ad-sec2-list {
    flex-direction: column;
  }
  .left-card {
    background-color: #13184f;
    width: 80%;
    margin: 20px auto;
    padding: 20px 30px;
    border-radius: 10px;
  }
  .sec3-container {
    padding: 50px 30px;
  }
  .tiles-margin {
    margin: 10px 0;
  }
  .site-details {
    height: auto;
    margin: 0;
    border-radius: 0px;
  }
  .register-box h5 {
    height: auto;
  }
  .site-img {
    height: 11rem;
  }
  .ads-banner-web {
    display: none;
  }
  .ads-banner-mob {
    display: block;
  }
}

@media (max-width: 768px) {
  .card-achv {
    flex-direction: column;
    z-index: 100000000;
    width: 100%;
    position: relative;
  }

  .boq-service {
    top: 10%;
  }

  .modal {
    z-index: 10000000000000 !important;
  }

  .register-box h5 {
    position: relative;
  }

  .close-cancel-btn {
    position: absolute;
    right: 3%;
    left: 90%;
    font-size: 22px;
    cursor: pointer;
  }
  .close-pass-btn {
    color: white;
    font-size: 22px;
    position: absolute;
    bottom: 95%;
    cursor: pointer;
    left: 92%;
  }
  .download-button {
    margin-top: 20px !important;
  }
  .comming-soon h4 {
    text-align: center;
  }
  .offer-box span {
    color: #13184f;
    font-size: 18px;
    background: rgba(178, 178, 178, 0.542);
    border-radius: 5px;
    padding: 3px;
  }
  .offer-box h3 {
    font-size: 18px !important;
  }
}
