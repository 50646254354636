.careerCardDiv {
    width: 100%;
    background-color: rgba(196, 196, 196, 0.12);
    border: 1px solid #0000001a;
    padding: 20px;
    border-radius: 5px;
    margin: 30px 0;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.careerRow {
    margin-top: 30px !important;
}

.partTime {
    background-color: rgba(255, 117, 39, 0.11);
    text-align: center;
    height: 25px;
    border-radius: 8px;
    color: rgba(0, 0, 0, 0.47);
}
.viewJobs {
    color: #0558e2;
    font-weight: 500;
    font-size: 14px !important;
}

.careerTop {
    height: 70vh;
    background: url("../Images/CareerTopBanner.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.careerPage {
    margin: auto;
    background-color: rgba(0, 0, 0, 0.05);
    padding: 6% 0;
}
.career-cardContent {
    text-align: center;
}
.inputDiv {
    width: 90%;
    box-shadow: 4px 4px 4px 0px #00000040;
    border: 1px solid #0000005e;
    border-radius: 8px;
    padding: 10px;
    background-color: white;
}

.inputCar {
    border: none;
}

.buttonDiv {
    border: 1px solid #0000005e;
    box-shadow: 4px 4px 4px 0px #00000040;
    border-radius: 10px;
}

.careerInputDiv {
    margin-top: -25px;
    margin-bottom: 40px;
}
.careerInputDiv h3 {
    font-weight: 600;
    margin-bottom: 30px;
}
.applyButton {
    background-color: #f47e20;
    color: white;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    margin-bottom: 50px;
    font-size: 20px;
    font-weight: 500;
}

/* //card */

.careerHead {
    height: 30vh;
    background-color: #ff7527d1;
    color: white;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
}
.CardFacility {
    margin-right: 50px;
}
.CardFacility h4 {
    font-size: 0.8rem;
    color: black;
    align-items: flex-start;
}

.CardFacility span h5 {
    color: rgba(28, 27, 77, 1);
    font-size: 0.7rem;
}
.CardFacility span {
    margin-left: 5px;
}
.CardFacility {
    display: flex;
    color: black;
}

.career-paragraph {
    max-height: 80px;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    color: #000;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
}

/* whole career */

.CareerInputBox {
    box-shadow: 6px 6px 16px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
}
.Search-Location {
    border-right: 0.5px solid rgb(185, 185, 185);
}

/*  career individual*/

.job-title button {
    background-color: var(--orangeColor);
    padding: 10px 30px;
    color: white;
    border-radius: 15px;
    border: #f47e20;
}
.job-title h1 {
    font-size: 40px;
    font-weight: 600;
}
.job-title button:hover {
    color: #f47e20;
    background: white;
    transition: 0.2s;
}
.jobdetails h3 {
    color: #f47e20;
    font-weight: 700;
}
.jobdetails-feature {
    align-items: center;
}
.jobdetails-feature h4 {
    font-size: 16px;
    padding-bottom: 0;
    margin: 0;
    margin-bottom: 0;
}
.jobdetails-feature p {
    font-size: 16px;
    margin: 0 0;
    margin-left: 10px;
    padding: 0;
    font-weight: 600;
}
.jobdetails-share span {
    margin-right: 15px;
}
.jobdetails-share span svg {
    font-size: 2rem;
}
.career-single-btn {
    border-radius: 5px;
    padding: 8px;
    width: 120px;
    height: 40px;
    background-color: var(--blueColor);
    color: white;
    border: none;
}
.career-modal-single {
    position: absolute;
    top: 50%;
    z-index: 10000000000000000000000;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 700px;
    background-color: white;
    border-radius: 10px;
    padding: 30px;
}

.career-modal-single h4 {
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--blueColor);
}

@media screen and (min-width: 300px) and (max-width: 460px) {
    .career-modal-single {
        width: 400px;
    }
}

@media (max-width: 460px) {
    .career-modal-single {
        width: 400px;
    }
    .CardFacility {
        margin-right: 30px;
    }
    .location-temp-height {
        margin-top: 20px;
    }
    .location-temp-height svg {
        margin-right: 20px !important;
    }
    .job-title h1 {
        font-size: 24px;
        font-weight: 600;
    }
}

.CareeBanner img {
    border-radius: 10px;
    width: 100%;
}
