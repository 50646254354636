.counter {
  color: #fff;
  font-family: "Poppins", sans-serif;
  text-align: center;
  padding: 22px 0 0;
  margin: 0 10px;
  position: relative;
}
.counter:before {
  content: "";
  border: 2px solid #575757;
  border-radius: 10px;
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  z-index: 1;
}
.counter .counter-icon {
  color: #13184f;
  font-size: 40px;
  margin: 0 0 22px;
}
.counter .counter-content {
  background-color: #13184f;
  color: white;
  padding: 26px 18px 20px;
  border-radius: 0 0 10px 10px;
  position: relative;
}
.counter .counter-content:before {
  content: "";
  background-color: #13184f;
  width: 25px;
  height: 25px;
  transform: translateX(-50%) rotate(45deg);
  position: absolute;
  left: 50%;
  top: -13px;
}
.counter h3 {
  font-size: 20px;
  font-weight: 400;
  text-transform: capitalize;
  margin: 0 0 8px;
}
.counter .counter-value {
  font-size: 40px;
  font-weight: 500;
}
.counter.red .counter-icon {
  color: #f47e20;
}
.counter.red .counter-content,
.counter.red .counter-content:before {
  background-color: #f47e20;
}
.counter.blue .counter-icon {
  color: #13184f;
}
.counter.blue .counter-content,
.counter.blue .counter-content:before {
  background-color: #13184f;
}
.counter.purple .counter-icon {
  color: #13184f;
}
.counter.purple .counter-content,
.counter.purple .counter-content:before {
  background-color: #13184f;
}
@media screen and (max-width: 990px) {
  .counter {
    margin-bottom: 40px;
  }
}
