.main-timeline{
    font-family: 'Poppins', sans-serif;
    position: relative;
}
.main-timeline:after{
    content: '';
    display: block;
    clear: both;
}
.main-timeline .timeline{
    width: 50%;
    margin: 0 0 20px 20px;
    float: right;
}
.main-timeline .timeline-content{
    color: #fff;
    background: #13184F;
    text-align: center;
    padding: 20px 20px 20px 100px;
    border-radius: 0 20px 20px 0;
    box-shadow: 0 5px 5px rgba(0,0,0,0.3);
    display: block;
    position: relative;
}
.main-timeline .timeline-content:hover{ text-decoration: none; }
.main-timeline .timeline-year{
    color: #F47E20;
    background-color: #fff;
    font-size: 35px;
    font-weight: 600;
    text-align: center;
    line-height: 130px;
    width: 130px;
    height: 100%;
    border-radius: 98px 0 0 10px;
    box-shadow: 0 5px 5px rgba(0,0,0,0.3);
    position: absolute;
    left: -130px;
    top: 0;
}
.main-timeline .timeline-icon{
    color: #fff;
    font-size: 50px;
    transform: translateY(-50%);
    position: absolute;
    left: 30px;
    top: 50%;
}
.main-timeline .title{
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 0 0 5px;
}
.main-timeline .description{
    font-size: 13px;
    letter-spacing: 1px;
    margin: 0;
}
.main-timeline .timeline:nth-child(even){
    float: left;
    margin: 0 20px 20px 0;
}
.main-timeline .timeline:nth-child(even) .timeline-content{
    padding: 20px 100px 20px 20px;
    border-radius: 20px 0 0 20px;
}
.main-timeline .timeline:nth-child(even) .timeline-content:before{
    right:auto;
    left:0;
    clip-path: polygon(0 0, 100% 100%, 65% 0);
}
.main-timeline .timeline:nth-child(even) .timeline-year{
    left: auto;
    right: -130px;
    border-radius:0 98px 10px 0;
}
.main-timeline .timeline:nth-child(even) .timeline-icon{
    right: 30px;
    left: auto;
}
.main-timeline .timeline:nth-child(4n+2) .timeline-content{
    background: #F47E20
}
.main-timeline .timeline:nth-child(4n+2) .timeline-year{ color: #13184F; }
.main-timeline .timeline:nth-child(4n+3) .timeline-content{
    background: #13184F;
}
.main-timeline .timeline:nth-child(4n+3) .timeline-year{ color: #F47E20; }
.main-timeline .timeline:nth-child(4n+4) .timeline-content{
    background:#F47E20;
}
.main-timeline .timeline:nth-child(4n+4) .timeline-year{ color: #13184F; }
@media screen and (max-width:767px){
    .main-timeline .timeline{
        width: 100%;
        padding: 50px 0 0 0;
    }
    .main-timeline .timeline .timeline-content,
    .main-timeline .timeline:nth-child(even) .timeline-content{
        padding: 20px 15px 20px 60px;
        border-radius: 0 20px 20px 20px;
    }
    .main-timeline .timeline .timeline-year,
    .main-timeline .timeline:nth-child(even) .timeline-year{
        font-size: 25px;
        border-radius: 15px 15px 0 0;
        box-shadow: none;
        width: 100px;
        height: 50px;
        line-height: 50px;
        top: -50px;
        left: 0;
    }
    .main-timeline .timeline .timeline-icon,
    .main-timeline .timeline:nth-child(even) .timeline-icon{
        font-size: 28px;
        left: 15px;
        right: auto;
    }
}
@media screen and (max-width:576px){
    .main-timeline .title{ font-size: 18px; }
}