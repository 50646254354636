@media (min-width: 992px) {
    .image-resize {
        width: 80% !important;
       height: 200px !important;
        object-fit: contain;
    }}

    .image-resize {
        width: 80%;
        height: 100%;
        object-fit: contain;
    }