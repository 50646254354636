.separatorDiv{
    height: 5px;
    width: 200px;
    border-radius: 10px;
    background: linear-gradient(90deg, #13184F 0%, #F47E20 94.96%);
    margin-top: 70px;
    margin-bottom: 15px;

}
.horrizontal{
    height: 5px;
    width: 100%;
    margin: 5px 0;
    border-radius: 10px;
    background: linear-gradient(90deg, #13184F 0%, #F47E20 94.96%);

}
.Link-special-banner{
    background-color: rgba(244, 126, 32, 1);
    color: white;
    padding: 10px 20px;
    border-radius: 20px;
    font-weight: 600;
    cursor: pointer;
    border: none;
}
.Link-special-banner:hover{
    background-color: white;
    color: var(--orangeColor);
    transition: 0.5s;
    
}
.Link-special-banner-second{
    background-color: rgba(19, 24, 79, 0.4);
    color: white;
    cursor: pointer;
    border: 1px solid white;
    padding: 10px 15px;
    border-radius: 20px;
    font-weight: 600;
}
.Link-special-banner-second:hover{
    background: black;
    transition: 0.5s;
}
.changeGameContent p{
    font-size: 1.3rem;
    margin-bottom: 0;
}
.emiRow{
    border: 2px solid rgb(199, 197, 197);
    margin-top: 0;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 20px;
}
.EmiContainer{
   padding-top: 100px;
}
.emiBtn{
    padding: 10px;
    border-radius: 10px;
    border: none;
    background-color:#13184F;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.navigate-logo{
    position: absolute;
    bottom: 50px;
    left: 50px;
}

.navigate-logo img{
    height: 80px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

@media (max-width: 568px){
    .banner-btn{
        flex-direction: column;
    }
    .Link-special-banner-second{
        margin-top: 15px;
     }

     .navigate-logo{
        position: absolute;
        bottom: 180px;
        left: 10px;
    }
    
    .navigate-logo img{
        height: 40px;
        border-radius: 8px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
    
}